import { useRouter as useNextRouter, useSearchParams } from 'next/navigation';

export type Router = ReturnType<typeof useNextRouter> & {
  pushOrReturn: (url: string) => void;
};

export function useRouter(): Router {
  const router = useNextRouter();
  const searchParams = useSearchParams();

  const pushOrReturn = (url: string) => {
    const pushUrl =
      searchParams?.has('returnUrl') !== undefined
        ? searchParams.get('returnUrl') ?? url
        : url;

    return router.push(pushUrl);
  };

  return {
    ...router,
    pushOrReturn,
  };
}
