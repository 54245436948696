'use client';

import { Card, CardBody, Center, Container, Stack } from '@chakra-ui/react';
import { formatCDNUrl } from '@cinesj/admin/helpers';
import { assets } from '@cinesj/constants';
import Image from 'next/image';

import { LoginForm } from './login-form';

export function LoginPage() {
  return (
    <Container
      maxW="lg"
      py={{ base: '12', md: '24' }}
      px={{ base: '0', sm: '8' }}
    >
      <Stack spacing="8">
        <Center>
          <Image
            src={formatCDNUrl(assets.logoLarge.path)}
            alt="Logo cine São José"
            width={assets.logoLarge.dimensions.width}
            height={assets.logoLarge.dimensions.height}
          />
        </Center>
        <Card variant="outline">
          <CardBody>
            <LoginForm />
          </CardBody>
        </Card>
      </Stack>
    </Container>
  );
}
