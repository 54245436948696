'use client';

import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { MdOutlineLogin } from 'react-icons/md';

import { Button, Icon, VStack } from '@chakra-ui/react';
import { useAuthStore } from '@cinesj/admin/core/auth';
import { Notifications } from '@cinesj/admin/core/notifications';
import { useRouter } from '@cinesj/admin/core/router';
import { CommandError, NotFoundError } from '@cinesj/sdk';
import { Input, Password } from '@cinesj/web-ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

const formSchema = z.object({
  email: z.string().email('deve ser um email').min(1, 'o email é obrigatório'),
  password: z.string().min(1, 'a senha é obrigatória'),
});

type FormValues = z.infer<typeof formSchema>;

export function LoginForm() {
  const router = useRouter();
  const auth = useAuthStore(state => ({
    login: state.login,
    isAuthenticated: state.isAuthenticated,
  }));

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
  });

  const loginMutation = useMutation({
    mutationFn: auth.login,
    async onSuccess() {
      router.pushOrReturn('/');
      Notifications.success({
        title: 'Sucesso!',
        message: 'Login efetuado com sucesso',
      });
    },
    async onError(error) {
      if (error instanceof NotFoundError) {
        form.setError('email', {
          message: 'Usuário não encontrado',
        });
      } else if (error instanceof CommandError) {
        form.setError('password', {
          message: 'Senha inválida',
        });
      } else if (
        error instanceof Error &&
        error.message === 'You are not an admin'
      ) {
        Notifications.error({
          message: 'Você não possui permissão para acessar este recurso',
        });
      } else {
        Notifications.error({
          message: 'Um error não esperado aconteceu',
        });
      }
    },
  });

  const onSubmitHandler = form.handleSubmit(data => {
    loginMutation.mutate({
      email: data.email,
      password: data.password,
    });
  });

  useEffect(() => {
    if (auth.isAuthenticated) {
      router.pushOrReturn('/');
    }
  }, [auth.isAuthenticated]);

  return (
    <VStack as="form" onSubmit={onSubmitHandler}>
      <Input
        isRequired
        type="email"
        label="E-Mail"
        register={form.register('email')}
        error={form.formState.errors.email}
      />
      <Password
        isRequired
        label="Senha"
        register={form.register('password')}
        error={form.formState.errors.password}
      />
      <Button
        type="submit"
        w="full"
        isLoading={loginMutation.isPending}
        leftIcon={<Icon as={MdOutlineLogin} />}
      >
        Login
      </Button>
    </VStack>
  );
}
