import dynamic from 'next/dynamic';

import { LayoutSwitchProps } from './types';

const DashboardLayout = dynamic(() =>
  import('components/layout/dashboard').then(mod => mod.DashboardLayout),
);

const DashboardMain = dynamic(() =>
  import('components/layout/dashboard').then(mod => mod.DashboardMain),
);

const DashboardMainWithoutWrapper = dynamic(() =>
  import('components/layout/dashboard').then(
    mod => mod.DashboardMainWithoutWrapper,
  ),
);

export function LayoutSwitch({ children, layout }: LayoutSwitchProps) {
  switch (layout) {
    case 'dashboard':
      return (
        <DashboardLayout>
          <DashboardMain>{children}</DashboardMain>
        </DashboardLayout>
      );
    case 'dashboard-no-wrapper':
      return (
        <DashboardLayout>
          <DashboardMainWithoutWrapper>{children}</DashboardMainWithoutWrapper>
        </DashboardLayout>
      );

    default:
      return children;
  }
}
